import React from 'react'
import {Link} from 'gatsby'

/**
 * 
 * @param {String} themeColor Primary theme is default. Secondary theme is optional.
 * @returns JSX.Element
 */
export default function Pricing({themeColor}) {
  const color = themeColor ? `theme-${themeColor}` : 'theme-primary';
  return (
    <>
    <div className={`row pricing pricing--${color} gy-5`}>
        <div className="col-md-6 col-lg-4" data-aos="zoom-out" data-aos-duration="500" data-aos-delay="100">
            <div className="pricing__item">
                <h3 className="pricing__title">Podstawowy</h3>
                <h4 className="pricing__price">800 <span>zł/brutto</span></h4>
                <ul className="pricing__list">
                <li className="pricing__list-item">Strona typu One Page</li>
                <li className="pricing__list-item">CMS Wordpress (opcjonalnie)</li>
                <li className="pricing__list-item">Dostosowanie do urządzeń mobilnych</li>
                <li className="pricing__list-item">Optymalizacja szybkości</li>
                <li className="pricing__list-item">Zabezpieczenie strony</li>
                <li className="pricing__list-item pricing__list-item--is-unactive">Podstawowe SEO</li>
                <li className="pricing__list-item pricing__list-item--is-unactive">Projekt graficzny</li>
                <li className="pricing__list-item pricing__list-item--is-unactive">Hosting</li>
                </ul>
                <div className="pricing__footer">
                <Link to="/brief" className="btn btn-secondary">
                    Wycena
                </Link>
                </div>
            </div>
        </div>

        <div className="col-md-6 col-lg-4" data-aos="zoom-out" data-aos-duration="500" data-aos-delay="200">
            <div className="pricing__item">
                <h3 className="pricing__title">Standard</h3>
                <h4 className="pricing__price">od 1500 <span>zł/brutto</span></h4>
                <ul className="pricing__list">
                <li className="pricing__list-item">Do 5 podstron</li>
                <li className="pricing__list-item">CMS Wordpress</li>
                <li className="pricing__list-item">Dostosowanie do urządzeń mobilnych</li>
                <li className="pricing__list-item">Optymalizacja szybkości</li>
                <li className="pricing__list-item">Zabezpieczenie strony</li>
                <li className="pricing__list-item">Podstawowe SEO</li>
                <li className="pricing__list-item pricing__list-item--is-unactive">Projekt graficzny</li>
                <li className="pricing__list-item pricing__list-item--is-unactive">Hosting</li>
                </ul>
                <div className="pricing__footer">
                <Link to="/brief" className="btn btn-secondary">
                    Wycena
                </Link>
                </div>
            </div>
        </div>

        <div className="col-md-6 col-lg-4" data-aos="zoom-out" data-aos-duration="500" data-aos-delay="300">
            <div className="pricing__item">
                <h3 className="pricing__title">Premium</h3>
                <span className="pricing__recommended">Polecany</span>
                <h4 className="pricing__price">od 2000 <span>zł/brutto</span></h4>
                <ul className="pricing__list">
                <li className="pricing__list-item">Do 10 podstron</li>
                <li className="pricing__list-item">CMS Wordpress</li>
                <li className="pricing__list-item">Dostosowanie do urządzeń mobilnych</li>
                <li className="pricing__list-item">Optymalizacja szybkości</li>
                <li className="pricing__list-item">Zabezpieczenie strony</li>
                <li className="pricing__list-item">Podstawowe SEO</li>
                <li className="pricing__list-item">Projekt graficzny</li>
                <li className="pricing__list-item">Hosting</li>
                </ul>
                <div className="pricing__footer">
                <Link to="/brief" className="btn btn-secondary">
                    Wycena
                </Link>
                </div>
            </div>
        </div>

        </div>

        <div className="row gy-5 gy-md-0 inner-section" data-aos="fade-in">
          <h2 className="section__title section__title--is-small">Dodatkowe usługi</h2>

          <div className="col-md-4">
            <div className={`pricing-box pricing-box--${color}`}>
              <h3 className="pricing-box__title">Dodatkowa wersja językowa</h3>
              <p className="pricing-box__value">450 zł</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className={`pricing-box pricing-box--${color}`}>
              <h3 className="pricing-box__title">System rezerwacji</h3>
              <p className="pricing-box__value">580 zł</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className={`pricing-box pricing-box--${color}`}>
              <h3 className="pricing-box__title">Podłącznie Google Analytics</h3>
              <p className="pricing-box__value">110 zł</p>
            </div>
          </div>

        </div>
    </>
  )
}
