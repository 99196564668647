import React from "react"
import { Link } from 'gatsby'
import Seo from '../components/Seo'
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import Pricing from "../components/Pricing"
import WebsiteFeaturesTabs from '../components/WebsiteFeaturesTabs'
import {Tab, TabButton, TabPanel, TabNav} from "../components/Tab"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faLocationDot, faPalette, faGlobe, faCloudArrowUp, faSuitcaseMedical, faRocket } from '@fortawesome/free-solid-svg-icons'
import SvgFooter from '../components/SvgFooter'
import Portfolio from "../components/Portfolio"

const OfferContent = ({title, hasColoredBackground, children}) => {
    const classes = hasColoredBackground ? 'websites-offer__title websites-offer__title--on-colored-bg' : 'websites-offer__title';
    return (
        <>
        {title && <h4 className={classes}>{title}</h4>}
        {children}
        </>
    )
}

const OfferImage = ({src, alt, animation}) => {
    return <img 
    data-src={src} 
    data-aos={animation}
    alt={alt} 
    className="websites-offer__img lazy" 
    src="/pixel.gif" 
    width="600" height="500" />;
}

const TabStageBadge = ({stage}) => {
    return <>Etap <span className="badge bg-secondary">{stage}</span></>
}

export default function Home() {
    return (
        <>
        <Seo title="Projektowanie i tworzenie stron internetowych" description="Profesjonalne strony internetowe na każdą kieszeń. Strony internetowe Bydgoszcz i cała Polska. Sprawdź i wypełnij brief." />
        <Layout title="Projektowanie i tworzenie stron internetowych">
        <section className="section pb-0">
            <div className="section pt-0">
                <div className="container">
                    <SectionTitle title="Strony internetowe" />
                    <div className="row gy-5">
                        <div className="col-12 col-md-6">
                            <p>Oferuję kompleksową ofertę stworzenia i wdrożenia profesjonalnej strony internetowej od podstaw lub na wybranym, gotowym szablonie w oparciu o system <strong>Wordpress</strong>.</p>
                            <p>Projektując i tworząc strony internetowe kładę duży nacisk na intuicyjność, szybkość działania strony, bezpieczeństwo oraz dostosowanie pod najpopularniejsze urządzenia i przeglądarki. Każdy tworzony projekt jest traktowany indywidualnie, z dbałością o najmniejsze szczegóły tak, aby strona była zoptymalizowana i łatwa w utrzymaniu.</p>
                            <Link to="/brief" className="btn btn-secondary btn--with-icon mt-3" title="Bezpłatna wycena strony internetowej">
                                <FontAwesomeIcon icon={faRocket} />
                                Zamów stronę internetową
                            </Link>
                        </div>
                        <div className="col-12 col-md-6">
                            <OfferImage 
                                src="/offer.svg" 
                                alt="Tworzenie stron internetowych"
                                animation="fade-left"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section section--with-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 class="section__normal-title" data-aos="fade-right">Co otrzymasz po zakupie strony internetowej?</h3>
                        </div>

                        <div className="features-cards" data-aos="fade-up">

                            <div className="card card--not-hoverable">
                                <div className="card-body">
                                    <div className="card__icon-container">
                                        <svg className="card__icon-background" width="100" height="100" viewBox="0 0 600 600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                                            d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174">
                                            </path>
                                        </svg>
                                        <FontAwesomeIcon className="card__icon" icon={faGlobe} />
                                    </div>
                                    <h4 className="card-title">Funkcjonalna strona internetowa</h4>
                                    <p className="mb-0">
                                        Strona zostanie zaprojektowana i skonfigurowana pod wymagania klienta. Tworzone przeze mnie strony są szybkie i responsywne. To oznacza, że Twoja strona będzie dobrze działać na każdym urządzeniu w popularnych przeglądarkach internetowych.
                                    </p>
                                </div>
                            </div>

                            <div className="card card--not-hoverable">
                                <div className="card-body">
                                    <div className="card__icon-container">
                                        <svg className="card__icon-background" width="100" height="100" viewBox="0 0 600 600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                                            d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                                            </path>
                                        </svg>
                                        <FontAwesomeIcon className="card__icon" icon={faCloudArrowUp} />
                                    </div>
                                    <h4 className="card-title">Uruchomienie strony</h4>
                                    <p className="mb-0">
                                        Gdy witryna zostanie ukończona i zaakceptowana, a płatność sfinalizowana to wdrożę Twoją stronę na docelowy serwer pod właściwą domenę. <br></br>Nie posiadasz własnego hostingu? Możesz skorzystać z mojej oferty.
                                    </p>
                                </div>
                            </div>

                            <div className="card card--not-hoverable">
                                <div className="card-body">
                                    <div className="card__icon-container">
                                        <svg className="card__icon-background" width="100" height="100" viewBox="0 0 600 600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                                            d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781">
                                            </path>
                                        </svg>
                                        <FontAwesomeIcon className="card__icon" icon={faSuitcaseMedical} />
                                    </div>
                                    <h4 className="card-title">Wsparcie projektu</h4>
                                    <p className="mb-0">
                                        Po finalizacji płatności i wdrożeniu strony na serwer, w cenie witryny oferuję półroczne wsparcie. Każdy ewentualny błąd lub małe poprawki zrobię za darmo. Po tym czasie też możesz zgłosić się do mnie o pomoc.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="section">
                <div className="websites-offer">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-12 col-lg-6 d-flex align-items-center">
                                <div data-aos="fade-right">
                                    <OfferContent title="Projektowanie stron WWW">
                                        <p>Proces tworzenia strony internetowej zaczyna się od projektu. Jest to zaplanowanie układu elementów, nawigacji oraz rozmieszczenia przygotowanych treści i grafik. Do każdego projektu podchodzę indywidualnie i nie korzystam z gotowych szablonów, chyba, że na życzenie klienta.</p>
                                        <p>Jeszcze zanim rozpocznę pracę nad projektowaniem, określam <strong>branżę i konkurencyjne witryny</strong> oraz cel, któremu ma służyć strona. Ważne jest też, aby określić <strong>grupę odbiorców</strong>, którzy będą wchodzić w interakcję z witryną.</p>
                                        <p>Klient z mniejszym budżetem może pominąć etap projektowania i zdać się na moje doświadczenie i estetykę. W takim przypadku wykonanie strony internetowej będzie tańsze, ponieważ wizji strony, która zazwyczaj tworzy mi się w głowie, nie muszę przenosić na projekt graficzny, tylko od razu przechodzę do programowania.</p>
                                    </OfferContent>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex justify-content-center">
                                <OfferImage 
                                    src="/projektowanie.svg" 
                                    alt="Projektowanie stron WWW"
                                    animation="fade-left"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="section section--with-bg">
                <div className="websites-offer">
                    <div className="container">
                        <div className="row gy-5 flex-wrap-reverse">
                            <div className="col-12 col-lg-6 d-flex justify-content-center">
                                <OfferImage 
                                    src="/programowanie.svg" 
                                    alt="Tworzenie stron WWW"
                                    animation="fade-right"
                                />
                            </div>
                            <div className="col-12 col-lg-6 d-flex align-items-center">
                                <div data-aos="fade-left">
                                    <OfferContent title="Tworzenie stron WWW" hasColoredBackground={true}>
                                        <p><strong>Tworzę strony internetowe</strong> w oparciu o najpopularniejszy na świecie system CMS - <strong>Wordpress</strong>. Dzięki temu narzędziu możesz zarządzać swoją stroną bez potrzeby posiadania wiedzy informatycznej.</p>
                                        <p>Strony stworzone na Wordpressie przez nieodpowiednich programistów często są wolne i narażone na ataki hakerskie — ja <strong>zapewnie Ci szybkość i bezpieczeństwo</strong>.</p>
                                        <p>Tworzone przeze mnie witryny są <strong>zoptymalizowane i bezpieczne</strong>.</p>
                                    </OfferContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="websites-offer">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-12 col-lg-6 d-flex align-items-center" data-aos="fade-right">
                                <div>
                                <OfferContent title="Naprawa stron WWW">
                                    <p>Posiadasz już stronę na Wordpressie, na której pojawiają się błędy? Podejrzewasz, że Twoja witryna została zainfekowana wirusem? <strong>Pomogę Ci ją naprawić oraz pozbyć się wirusów</strong>.</p>
                                </OfferContent>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex justify-content-center">
                                <OfferImage 
                                    src="/naprawa.svg" 
                                    alt="Naprawa stron WWW"
                                    animation="fade-left"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        {/* Pricing */}
        <section id="pricing" className="section section--with-bg">
            <div className="container">
                <SectionTitle title="Cennik" subtitle="Poniższe ceny są tylko orientacyjne. Każdy projekt jest wyceniony indywidualnie w zależności od potrzeb." />
                <Pricing themeColor="secondary" />
            </div>
        </section>

        <section className="section">
            <div className="container">
                <SectionTitle 
                    title="Współpraca" 
                    subtitle="Jak wygląda współpraca ze mną?"
                />

                <div className="row" data-aos="fade-up">
                    <div className="col-12">
                        <Tab>
                            <TabNav orientation="vertical" tabId="partnership">
                                <TabButton id="first-step-tab" targetID="first-step" active={true}>1. Brief</TabButton>
                                <TabButton id="second-step-tab" targetID="second-step">2. Plan</TabButton>
                                <TabButton id="third-step-tab" targetID="third-step">3. Projekt</TabButton>
                                <TabButton id="fourth-step-tab" targetID="fourth-step">4. Programowanie</TabButton>
                                <TabButton id="fifth-step-tab" targetID="fifth-step">5. Wdrożenie</TabButton>
                            </TabNav>
                            <div className="tab-content card card--not-hoverable" id="partnershipContent">
                                <TabPanel id="first-step" title={<TabStageBadge stage="1" />} active={true}>
                                    <p className="mb-0">Pierwszy etap współpracy polega na przedstawieniu Twoich potrzeb i oczekiwań. Wypełnisz <strong>brief, który ułatwi mi zrozumieć i wycenić Twoje wymagania</strong>. Na podstawie bezpłatnej wyceny podejmiesz decyzje czy podejmiemy współpracę.</p>
                                </TabPanel>
                                <TabPanel id="second-step" title={<TabStageBadge stage="2" />}>
                                    <p className="mb-0">Etap planowania zaczyna się od zebrania treści, grafik i ogólnych potrzeb klienta. W tym momencie w mojej głowie zaczyna powstawać koncept Twojej strony.</p>
                                </TabPanel>
                                <TabPanel id="third-step" title={<TabStageBadge stage="3" />}>
                                    <p className="mb-0">Mając gotowe treści i grafiki oraz plan koncepcyjny, przechodzimy do faktycznego projektowania strony. Na końcu tego etapu klient otrzymuje tzw. makietę do oceny. Jest to projekt graficzny przedstawiający jak będzie wyglądać strona.</p>
                                </TabPanel>
                                <TabPanel id="fourth-step" title={<TabStageBadge stage="4" />}>
                                    <p className="mb-0">Jest to etap, w którym powstaje Twoja strona. Programowanie w zależności od skomplikowania projektu zajmuje mi mniej więcej 2 tygodnie. Po skończeniu pracy udostępniam stronę do testowania.</p>
                                </TabPanel>
                                <TabPanel id="fifth-step" title={<TabStageBadge stage="5" />}>
                                    <p className="mb-0">Finalny etap czyli przeniesienie strony na docelowy serwer i podpięcie domeny. W tym momencie następuje również rozliczenie za wykonaną pracę oraz przekazanie dostępu do panelu zarządzania stroną.</p>
                                </TabPanel>
                            </div>
                        </Tab>
                    </div>
                </div>

            </div>
        </section>

        <section className="section section--with-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h3 className="section__normal-title" data-aos="fade-right">Co wyróżnia tworzone przeze mnie projekty?</h3>
                        <div className="features-cards" data-aos="fade-up">
                            <div className="card card--not-hoverable">
                                <div className="card-body">
                                    <div className="card__icon-container">
                                        <svg className="card__icon-background" width="100" height="100" viewBox="0 0 600 600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                                            d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174">
                                            </path>
                                        </svg>
                                        <FontAwesomeIcon className="card__icon" icon={faClock} />
                                    </div>
                                    <h4 className="card-title">Szybkość działania</h4>
                                    <p className="mb-0">
                                        Ludzie w internecie są niecierpliwi i nie lubią długo czekać, aż strona się załaduje. Szybkość działania docenią również algorytmy Google'a, które nagrodzą szybką witrynę wyższymi pozycjami.
                                    </p>
                                </div>
                            </div>

                            <div className="card card--not-hoverable">
                                <div className="card-body">
                                    <div className="card__icon-container">
                                        <svg className="card__icon-background" width="100" height="100" viewBox="0 0 600 600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                                            d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                                            </path>
                                        </svg>
                                        <FontAwesomeIcon className="card__icon" icon={faLocationDot} />
                                    </div>
                                    <h4 className="card-title">Intuicyjność</h4>
                                    <p className="mb-0">
                                        Klient nie ma czasu, aby błądzić po Twojej stronie w poszukiwaniu informacji czy wezwania do działania. Dobrze zaprojektowana strona sprawia, że internauta bez problemu porusza się po stronie i odnajduje interesujące go informacje.
                                    </p>
                                </div>
                            </div>

                            <div className="card card--not-hoverable">
                                <div className="card-body">
                                    <div className="card__icon-container">
                                        <svg className="card__icon-background" width="100" height="100" viewBox="0 0 600 600"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                                            d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781">
                                            </path>
                                        </svg>
                                        <FontAwesomeIcon className="card__icon" icon={faPalette} />
                                    </div>
                                    <h4 className="card-title">Design</h4>
                                    <p className="mb-0">
                                        Internauci lubią czuć komfort przeglądając strony internetowe. Dobrze zaprojektowana i nowocześnie wyglądają witryna sprawi, że użytkownik spędzi na niej więcej czasu, co również docenią i nagrodzę algrorytmy Google'a.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section className="section">
            <div className="container">

                <div className="row">
                    <div className="col-12">
                        <h3 className="section__normal-title" data-aos="fade-right">Jaka powinna być strona internetowa?</h3>
                        <WebsiteFeaturesTabs />
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-5 pt-5">
                    <div className="col-12 col-md-9">
                        <div className="card card--not-hoverable text-center partnership" data-aos="fade-up">
                            <div className="card-body">
                                <h4 className="partnership__title">Zainteresowany współpracą?</h4>
                                <p>Wypełnij brief, aby otrzymać bezpłatną wycenę lub skontaktuj się ze mną jeśli masz jakieś pytania.</p>
                                <div className="d-flex flex-wrap justify-content-center gap-3">
                                    <Link to="/brief" className="btn btn-secondary" title="Bezpłatna wycena">Wycena</Link>
                                    <Link to="/kontakt" className="btn btn-secondary" title="Kontakt">Kontakt</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        {/* Portfolio */}
        <section id="portfolio" className="section section--with-bg section--with-svg">
            <div className="container" data-aos="fade-up">
                <SectionTitle title="Portfolio" />
                <Portfolio />
            </div>
            <SvgFooter />
        </section>

        </Layout>
        </>
    )
}