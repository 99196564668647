import React from 'react'
import {Link} from 'gatsby'
import projects from '../../content/projects.json'
import ProjectOnList from '../components/projects/ProjectOnList'

export default function Portfolio() {
  return (
    <>
    <div className="row portfolio">
        <div className="col-12">
          <ul className="portfolio__wrapper">
          {
            projects.map( (project, i) => {
              if(!project.isOnHomePage) {
                return null;
              }

              return (
                <ProjectOnList key={project.slug} project={project} index={i} />
              )
            })
          }
          </ul>
        </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 text-center">
        <Link className="btn btn-secondary" to="/projekty" title="Zobacz moje realizacje">Zobacz więcej</Link>
      </div>
    </div>
    </>
  )
}
