import React from 'react';
import {Link} from 'gatsby';

export default function ProjectOnList({project, index}) {
    const animationDelay = 65 * (index * 2);
      
    return (
        <li className="portfolio__item" data-aos="fade-in" data-aos-duration="900" data-aos-delay={animationDelay}>
          <img src="/pixel.gif" data-src={project.image} className="portfolio__photo lazy" alt={project.name} height="350" width="374" />
          <div className="portfolio__hidden-content">
            <span className="portfolio__category">{project.category}</span>
            <p className="portfolio__desc">{project.description}</p>
            <Link to={`/projekty/${project.slug}`} className="portfolio__link">Zobacz</Link>
          </div>
        </li>
     )
}
