import React from 'react'

export const TabButton = ({id, targetID, children, active}) => {
    return (
        <button className={active ? 'nav-link flex-grow-1 active' : 'nav-link flex-grow-1'} 
        id={id} 
        data-bs-toggle="pill"
        data-bs-target={'#'+targetID}
        role="tab">
            {children}
        </button>
    )
}

export const TabPanel = ({children, id, active, title}) => {
    return (
        <div className={active ? 'tab-pane active' : 'tab-pane'} id={id} role="tabpanel">
            {title && <h4 className="tab-pane__title">{title}</h4>}
            {children}
        </div>
    )
}

export const TabNav = ({children, classes, orientation, tabId}) => {
    return (
        <div className={classes ? classes : 'nav flex-row flex-md-column nav-pills me-md-5 mb-3 mb-md-0'} 
        id={tabId} 
        aria-orientation={orientation} role="tablist">
            {children}
        </div>
    )
}

export function Tab({children}) {
  return (
    <div className="tab-list d-md-flex align-items-center">
        {children}
    </div>
  )
}
