import React from 'react'
import {Tab, TabButton, TabPanel, TabNav} from '../components/Tab'

export default function WebsiteFeaturesTabs() {
    return (
        <div data-aos="fade-up">
            <Tab>
                <TabNav orientation="vertical" tabId="webpageFeatures">
                    <TabButton id="first-tab" targetID="first" active={true}>Nowoczesna</TabButton>
                    <TabButton id="second-tab" targetID="second">Szybka</TabButton>
                    <TabButton id="third-tab" targetID="third">Responsywna</TabButton>
                    <TabButton id="fourth-tab" targetID="fourth">Czytelna</TabButton>
                    <TabButton id="fifth-tab" targetID="fifth">Zabezpieczona</TabButton>
                </TabNav>
                <div className="card card--not-hoverable tab-content" id="webpageFeaturesContent">
                    <TabPanel id="first" active={true} title="Nowoczesny design i estetyka">
                        <p className="mb-0">Strona internetowa oprócz przekazywania informacji musi być również nowoczesna i estetyczna, aby wyróżnić się spośród tysięcy innych podobnych stron w danej branży. Tworzę strony internetowe z myślą o praktyczności i estetyce, korzystając z najnowszych trendów.</p>
                    </TabPanel>
                    <TabPanel id="second" title="Szybkość ładowania strony">
                        <p className="mb-0">Szybkość ładowania strony wpływa na to czy użytkownik w ogóle ją odwiedzi. Według badań, wiele ludzi wychodzi ze strony, która ładuje się dłużej niż 3 sekundy. <strong>Zoptymalizowana, przemyślana i szybka strona</strong>, pozytywnie wpłynie na użytkownika co często przyczynia się do zwiększenia sprzedaży w porównaniu do niezoptymalizowanej, wolnej witryny.</p>
                    </TabPanel>
                    <TabPanel id="third" title="Prawidłowe wyświetlanie na różnych urządzeniach">
                        <p className="mb-0">Użytkownik może przeglądać stronę internetową na telefonie, tablecie, laptopie, komputerze, telewizorze, a nawet zegarku - sporo, prawda? <strong>Strona internetowa powinna wyświetlać się prawidłowo na każdym urządzeniu</strong>. Dokładam wiele starań do tego, aby strona wyświetlała się bardzo dobrze, bez względu na jakim urządzeniu jest wyświetlana.</p>
                    </TabPanel>
                    <TabPanel id="fourth" title="Przejrzysta i przyjazna strona">
                        <p className="mb-0"><strong>Przemyślana struktura, odpowiednie odstępy i kontrast</strong> to jedne z wielu czynników wpływających na czytelność strony. Nieprzyjazna strona sprawia, że użytkownik ma problem z odnalezieniem potrzebnych informacji, co powoduje szybkie wyjście i utratę potencjalnego klienta. Dołożę wszelkich starań, aby Twoja strona była czytelna i przyjazna dla każdego użytkownika.</p>
                    </TabPanel>
                    <TabPanel id="fifth" title="Bezpieczeństwo strony">
                        <p className="mb-0">Wdrożenie bezpiecznego protokołu SSL to podstawa i na tym wiele firm kończy temat bezpieczeństwa. Strony oparte o Wordpress, w szczególności są narażone na liczne ataki różnego rodzaju, dlatego <strong>dbam o możliwie najwyższe zabezpieczenie stron</strong>. Dzięki działaniom bezpieczeństwa, tworzone przeze mnie strony są odporne na większość masowych ataków.</p>
                    </TabPanel>
                </div>
            </Tab>
        </div>
    )
}