import React from 'react'

const Subtitle = ({value}) => {
    return (
        <p className="section__desc">
            {value}
        </p>
    );
}

export default function SectionTitle({title, subtitle}) {
    return (
        <div data-aos="fade-in">
            <h2 className="section__title" data-text={title}>{title}</h2>
            {subtitle && <Subtitle value={subtitle} />}
        </div>
    );
}